
@each $color, $value in $theme-colors {
  .circle-#{$color} {
    height: 12px;
    width: 12px;
    background-color: $value;
    border-radius: 50%;
    display: inline-block;

    @if $color == 'info-text' {
      border: none !important;
    }
  }
}
