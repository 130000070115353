//
// Base styles
//

.btn {
  display: inline-block;
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  color: $body-color;
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  background-color: transparent;
  border: $btn-border-width solid transparent;
  @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius, $btn-line-height);
  @include transition($btn-transition);

  &:hover {
    color: $body-color;
    text-decoration: if($link-hover-decoration == underline, none, null);
  }

  .btn-check:focus + &,
  &:focus {
    outline: 0;
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    pointer-events: none;
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);


    @if $color == 'info-text' {
      border: none !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end btn-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-400;
  color: $btn-link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $btn-link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
  }

  // No need for an active state here
}


//
// Button Sizes
//

.btn-outline {
  @include button-size($btn-padding-y-xl-outline, $btn-padding-x-xl-outline, $btn-font-size-xl, $btn-border-radius-lg, $btn-line-height-xl);
}

.btn-xl {
  @include button-size($btn-padding-y-xl, $btn-padding-x-xl, $btn-font-size-xl, $btn-border-radius-lg, $btn-line-height-xl);
}

.btn-xl-outline {
  @include button-size($btn-padding-y-xl-outline, $btn-padding-x-xl-outline, $btn-font-size-xl, $btn-border-radius-lg, $btn-line-height-xl);
}

.btn-lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg);
}

.btn-lg-outline {
  @include button-size($btn-padding-y-lg-outline, $btn-padding-x-lg-outline, $btn-font-size-lg, $btn-border-radius-lg, $btn-line-height-lg);
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm);
}

.btn-sm-outline {
  @include button-size($btn-padding-y-sm-outline, $btn-padding-x-sm-outline, $btn-font-size-sm, $btn-border-radius-sm, $btn-line-height-sm);
}
